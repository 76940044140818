import styled from "styled-components";
import { sizes } from "../config/sizes";
import { Affix, Col, Drawer, Form, Row, Card } from "antd";
import { useEffect, useRef, useState } from "react";
import { useSize, useToggle } from "ahooks";
import { useInterface } from "../contexts/interface";
import ClashCard from "./Card";
import {
    isString,
    intersection,
    isArray,
    map,
    forEach,
    filter,
    isInteger,
} from "lodash";
import InputSelectAbility from "./input/SelectAbility";
import InputSelectNumber, { Mode } from "./input/SelectNumber";
import { ICard, ICardKeys } from "../types/card";
import InputSearchName from "./input/SearchName";
import InputSearchExtension from "./input/SearchExtension";

const deckSize = 4;

const StyledDiv = styled.div<{ measures: any }>`
    cursor: pointer;
    display: block;
    padding-top: ${(p) => p.measures.cardOffset * deckSize}px;
    padding-bottom: ${(p) => p.measures.cardOffset}px;
    width: 100%;

    .deck {
        position: relative;
        margin: auto;
        width: ${(p) => p.measures.width}px;
        height: ${(p) => p.measures.height}px;
    }

    .card-back {
        z-index: 100;
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: url("/images/cards/card-back.png");
        background-size: contain;
        transition: all 0.5s;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.5);
    }

    &:hover {
        .card-back:last-child {
            bottom: ${(p) =>
                (deckSize + 1) * p.measures.cardOffset}px !important;
        }
        .card-back:nth-last-child(2) {
            bottom: ${(p) =>
                (deckSize - 0.5) * p.measures.cardOffset}px !important;
        }
        .card-back:nth-last-child(3) {
            bottom: ${(p) =>
                (deckSize - 2) * p.measures.cardOffset}px !important;
        }
    }
`;

const StyledDrawer = styled(Drawer)`
    .ant-drawer-body {
        background-image: url("/images/tapis/wood2.jpg");
        padding: 0;
        display: flex;
        flex-direction: column;
    }
    .ant-drawer-content-wrapper {
        box-shadow: 15px 0 15px 0 rgba(0, 0, 0, 0.75) !important;
    }
    .card {
        margin: 10px;
    }
    .cards {
        padding-top: 20px;
        overflow-y: scroll;
    }
    .filters {
        box-shadow: 0 15px 15px 0 rgba(0, 0, 0, 0.75);
    }
    .ant-form-item {
        margin-bottom: 5px;
    }
`;

const filterCards = (cards: any, filters: any) =>
    filter(cards, (card) => {
        let keepIt = true;
        forEach(filters, (value, key) => {
            const cardValue = card[key as ICardKeys];
            if (
                isArray(value) &&
                value.length &&
                (isInteger(cardValue) || cardValue === null)
            ) {
                //Value of card must be in array
                keepIt = keepIt && value.indexOf(cardValue) >= 0;
            }
            if (isArray(value) && value.length && isArray(cardValue)) {
                //All values of array must be in values of card
                keepIt =
                    keepIt &&
                    intersection(value, cardValue).length === value.length;
            }
            if (isString(value) && value && isString(cardValue)) {
                keepIt =
                    keepIt &&
                    cardValue.toLocaleLowerCase().includes(value.toLowerCase());
            }
        });
        return keepIt;
    });

/**
 * This components manage the Deck. It shows a deck of cards that opens a drawer with filtering & a list of cards
 * @constructor
 */
const Deck = ({}: {}) => {
    const [
        open,
        { toggle: toggleOpen, setLeft: setClosed, setRight: setOpened },
    ] = useToggle();
    const { cards } = useInterface();
    const [filteredCards, setFilteredCards] = useState<Record<number, ICard>>(
        filterCards(cards, { extension: [0] })
    );
    const [form] = Form.useForm();
    const ref = useRef(null);
    const size = useSize(ref);
    const [measures, setMeasures] = useState({
        width: sizes.card.width,
        height: sizes.card.height,
        cardOffset: 10,
    });

    //Filter cards
    const onFilter = () => {
        const filters = form.getFieldsValue();
        setFilteredCards(filterCards(cards, filters));
    };

    //Update deck size
    useEffect(() => {
        if (size) {
            //Compute width of our deck
            let width = size.width;
            const cardOffset = size.width * sizes.card.ratio * 0.03;

            //Height of our deck
            const height =
                width * sizes.card.ratio -
                cardOffset * deckSize -
                cardOffset * 2;

            setMeasures({
                height,
                width: height / sizes.card.ratio,
                cardOffset,
            });
        }
    }, [size]);

    return (
        <>
            <StyledDiv onClick={toggleOpen} ref={ref} measures={measures}>
                <div className="deck">
                    {[...Array(deckSize)].map((e, i) => (
                        <div
                            className="card-back"
                            key={i}
                            style={{ bottom: i * measures.cardOffset }}
                        />
                    ))}
                </div>
            </StyledDiv>

            <StyledDrawer
                title="Basic Drawer"
                placement="left"
                open={open}
                onClose={toggleOpen}
                headerStyle={{ display: "none" }}
            >
                <Form form={form} onValuesChange={onFilter}>
                    <Affix offsetTop={0.1}>
                        <div className="filters">
                            <Card>
                                <Form.Item name="extension" initialValue={[0]}>
                                    <InputSearchExtension />
                                </Form.Item>
                                <Form.Item name="name">
                                    <InputSearchName />
                                </Form.Item>
                                <Form.Item name="abilities">
                                    <InputSelectAbility />
                                </Form.Item>
                                <Form.Item name="aura">
                                    <InputSelectAbility placeholder="Filtrer par Aura" />
                                </Form.Item>
                                <Form.Item name="mana">
                                    <InputSelectNumber mode={Mode.mana} />
                                </Form.Item>
                                <Form.Item name="atk">
                                    <InputSelectNumber mode={Mode.atk} />
                                </Form.Item>
                                <Form.Item name="hp">
                                    <InputSelectNumber mode={Mode.hp} />
                                </Form.Item>
                            </Card>
                        </div>
                    </Affix>
                </Form>

                <Row className="cards">
                    {map(filteredCards, (card) => (
                        <Col
                            key={card.id}
                            span={12}
                            style={{ textAlign: "center" }}
                        >
                            <ClashCard
                                card={card}
                                onDragStart={setClosed}
                                onDragEnd={setOpened}
                            />
                        </Col>
                    ))}
                </Row>
            </StyledDrawer>
        </>
    );
};

export default Deck;
