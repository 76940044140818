import styled from "styled-components";
import { useInterface } from "../../contexts/interface";

interface Props {
    id: number;
    size: number;
}

const StyledDiv = styled.div<Props>`
    margin-top: 3px;
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;
    background-size: contain;
    display: inline-block;
` as any;

/**
 *
 * @param id
 * @param size
 * @constructor
 */
const DisplayAbility = ({ id, size = 40 }: Props) => {
    const { abilities } = useInterface();
    return (
        <StyledDiv
            className="ability"
            style={{
                backgroundImage:
                    "url('images/abilities/" + abilities[id].image + "')",
            }}
            size={size}
        ></StyledDiv>
    );
};

export default DisplayAbility;
