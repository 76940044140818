import React from "react";
import styled from "styled-components";
import { useControllableValue } from "ahooks";

const StyledDiv = styled.div`
    width: 100%;
    height: 40px;

    background: rgba(255, 255, 255, 0.05);
    padding: 2px 8px;
    border-radius: 6px;

    .number {
        opacity: 0.35;

        text-align: center;
        display: inline-block;
        height: 36px;
        line-height: 36px;
        color: #fff;
        font-weight: bold;

        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;

        cursor: pointer;

        &.atk {
            background-image: url("/images/backgrounds/atk.png");
        }
        &.hp {
            background-image: url("/images/backgrounds/hp.png");
        }
        &.mana {
            background-image: url("/images/backgrounds/mana.png");
        }
        &.selected {
            opacity: 1;
        }
    }
`;

export enum Mode {
    mana = "mana",
    hp = "hp",
    atk = "atk",
}

const InputSelectNumber = ({ min = 0, max = 9, mode = Mode.atk, ...props }) => {
    const [value, setValue] = useControllableValue<number[]>(props, {
        defaultValue: [],
    });

    const toggle = (number: number) => {
        const selected = value ?? [];
        const position = selected.indexOf(number);
        if (position >= 0) selected.splice(position, 1);
        else selected.push(number);
        setValue([...selected]);
    };

    const width = 100 / (max - min + 1);
    const items = [];
    for (let i = min; i <= max; i++) {
        items.push(
            <div
                key={i}
                className={
                    "number " +
                    mode +
                    (value?.indexOf(i) >= 0 ? " selected" : "")
                }
                style={{ width: width + "%" }}
                onClick={() => toggle(i)}
            >
                {i}
            </div>
        );
    }

    return <StyledDiv>{items}</StyledDiv>;
};

export default InputSelectNumber;
