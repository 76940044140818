import styled from "styled-components";
import { sizes } from "../config/sizes";
import { ICard } from "../types/card";
import { useDrop } from "react-dnd";
import Card from "./Card";
import { useControllableValue, useSize } from "ahooks";
import { useEffect, useMemo, useState } from "react";
import { mergeRefs } from "react-merge-refs";
import { Dropdown, Menu } from "antd";

const StyledDiv = styled.div<{ measures: any }>`
    position: relative;
    width: 100%;
    height: ${(p) => p.measures.slotHeight}px;
    display: inline-block;

    .card {
        position: absolute;
        top: ${(p) => p.measures.slotSpacing}px;
        left: ${(p) => p.measures.slotSpacing}px;
        width: ${(p) => p.measures.cardWidth}px;
        height: ${(p) => p.measures.cardHeight}px;
    }

    .slot-background {
        position: absolute;
        top: ${(p) => p.measures.slotSpacing / 2}px;
        left: ${(p) => p.measures.slotSpacing / 2}px;
        background: rgba(255, 255, 255, 0.3);
        border-radius: 10px;
        width: calc(100% - ${(p) => p.measures.slotSpacing}px);
        height: calc(100% - ${(p) => p.measures.slotSpacing}px);
    }

    .slot-name {
        z-index: 2;
        position: absolute;
        top: calc(50% - 15px);
        left: 0;
        width: 100%;
        font-size: ${(p) => p.measures.slotHeight * 0.12}px;
        line-height: 30px;
        font-weight: bold;
        text-align: center;
        user-select: none;
        pointer-events: none;

        opacity: 0.75;
        color: #fff;
        text-shadow: 1px 0 #000, -1px 0 #000, 0 1px #000, 0 -1px #000,
            1px 1px #000, -1px -1px #000, 1px -1px #000, -1px 1px #000;
    }
`;

const Slot = ({
    id,
    slotRef,
    name,
    locked = false,
    ...props
}: {
    id: number;
    slotRef: any;
    name: string;
    card?: ICard;
    onChange: any;
    locked?: boolean;
}) => {
    const [card, setCard] = useControllableValue(props, {
        valuePropName: "card",
    });
    const size = useSize(slotRef);

    //Default measures
    const [measures, setMeasures] = useState({
        slotHeight: 0,
        slotSpacing: 0,
        cardWidth: 0,
        cardHeight: 0,
    });

    //You can drop cards in a slot
    const [, dropRef] = useDrop(
        () => ({
            accept: "CARD",
            drop: setCard,
        }),
        []
    );

    //List of actions that can be performed on the slot
    const actions = useMemo(() => {
        const items = [];
        if (card)
            items.push({ label: "Supprimer la carte", key: "removeCard" });
        return items;
    }, [card]);

    //
    const performAction = ({ key }: any) => {
        if (key === "removeCard") {
            setCard(null);
        }
    };

    //We resize the slot depending on screen size. Card fits in slot
    useEffect(() => {
        if (!size) return;

        const { width } = size;
        const { ratio } = sizes.card;
        const slotWidth = width;
        const slotSpacing = slotWidth * 0.12;
        const slotHeight =
            (slotWidth - slotSpacing * 2) * ratio + slotSpacing * 2;
        const cardWidth = slotWidth - slotSpacing * 2;
        const cardHeight = slotHeight - slotSpacing * 2;

        setMeasures({ slotHeight, slotSpacing, cardWidth, cardHeight });
    }, [size]);

    return (
        <Dropdown
            overlay={<Menu items={actions} onClick={performAction} />}
            trigger={["contextMenu"]}
            disabled={locked}
        >
            <StyledDiv
                ref={mergeRefs([dropRef, slotRef])}
                className="slot"
                measures={measures}
                id={"slot-" + id}
            >
                <div className="slot-name">{name}</div>
                {!!card && <Card card={card} />}
                <div className="slot-background" />
            </StyledDiv>
        </Dropdown>
    );
};

export default Slot;
