import { Input } from "antd";
import { useControllableValue, useDebounceFn } from "ahooks";

const InputSearchName = (props: any) => {
    const [value, setValue] = useControllableValue(props);
    const { run: onChange } = useDebounceFn(setValue, { wait: 500 });
    return (
        <Input
            onChange={onChange}
            allowClear
            size="large"
            placeholder="Filtrer par Nom"
        />
    );
};

export default InputSearchName;
