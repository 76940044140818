import React, { useEffect, useMemo, useRef, useState } from "react";
import { useInterface } from "../contexts/interface";
import { find, toArray } from "lodash";
import { ICard } from "../types/card";
import { useHistory } from "react-router-dom";
import { useDebounceFn, useMount, useSize, useToggle } from "ahooks";
import Game from "../component/Game";
import useJsonCrush from "../hooks/json-crush";
import {
    InputNumber,
    Form,
    Select,
    Button,
    Modal,
    Switch,
    Alert,
    Input,
    notification,
    Rate,
} from "antd";
import InputPickerSolution from "../component/input/PickerSolution";
import styled from "styled-components";
import useWindowDimensions from "../hooks/window-dimensions";
import {
    CopyOutlined,
    ShareAltOutlined,
    WarningFilled,
} from "@ant-design/icons/lib";
import copy from "copy-to-clipboard";

const StyledDiv = styled.div`
    margin: auto;
    background: rgb(20, 20, 20);
    padding-bottom: 20px;

    .game {
        max-width: 100%;
    }

    .ant-form-item:not(:first-child) {
        margin-top: 20px;
        margin-left: 20px;
        margin-right: 20px;
    }
`;

/**
 * This page will initialize the Maker and arrange lines & the deck
 * @constructor
 */
const PageMaker = () => {
    const [form] = Form.useForm();
    const { cards } = useInterface();
    const ref = useRef(null) as any;
    const size = useSize(ref);
    const [maxWidth, setMaxWidth] = useState(1440);
    const { height: viewportHeight } = useWindowDimensions();
    const history = useHistory();
    const [sharedUrl, setSharedUrl] = useState("");
    const { objectives } = useInterface();
    const objectivesOptions = toArray(objectives).map((o) => ({
        value: o.id,
        name: o.name,
        label: (
            <span>
                <b>{o.name}</b> - {o.description}
            </span>
        ),
    }));
    const { compress, decompress } = useJsonCrush();
    const [openSharing, { toggle: toggleSharing }] = useToggle(false);
    const defaultSlots = useMemo(
        () => [
            { id: 1, lineId: 1, name: "A", ref: React.createRef() },
            { id: 2, lineId: 1, name: "B", ref: React.createRef() },
            { id: 3, lineId: 1, name: "C", ref: React.createRef() },
            { id: 4, lineId: 2, name: "D", ref: React.createRef() },
            { id: 5, lineId: 2, name: "E", ref: React.createRef() },
            { id: 6, lineId: 2, name: "F", ref: React.createRef() },
            { id: 7, lineId: 3, name: "G", ref: React.createRef() },
            { id: 8, lineId: 3, name: "H", ref: React.createRef() },
            { id: 9, lineId: 3, name: "I", ref: React.createRef() },
            { id: 10, lineId: 4, name: "J", ref: React.createRef() },
            { id: 11, lineId: 4, name: "K", ref: React.createRef() },
            { id: 12, lineId: 4, name: "L", ref: React.createRef() },
            {
                id: 13,
                lineId: 5,
                name: "1",
                ref: React.createRef(),
                card: {
                    ...find(cards, { name: "bastion" }),
                    inSlot: 13,
                } as ICard,
            },
            { id: 14, lineId: 5, name: "2", ref: React.createRef() },
            { id: 15, lineId: 5, name: "3", ref: React.createRef() },
            { id: 16, lineId: 5, name: "4", ref: React.createRef() },
            { id: 17, lineId: 5, name: "5", ref: React.createRef() },
            { id: 18, lineId: 5, name: "6", ref: React.createRef() },
            { id: 19, lineId: 5, name: "7", ref: React.createRef() },
            { id: 20, lineId: 5, name: "8", ref: React.createRef() },
            { id: 21, lineId: 5, name: "9", ref: React.createRef() },
        ],
        []
    );
    const [slots, setSlots] = useState(defaultSlots);
    const { run: resize } = useDebounceFn(
        () => {
            if (size) {
                const ratio =
                    Math.ceil(
                        ((size?.height ?? 0) / (size?.width ?? 1)) * 100
                    ) / 100;
                setMaxWidth(viewportHeight / ratio);
            }
        },
        { wait: 25 }
    );

    //Update URL on values change
    const onValuesChange = (changedValue: any, allValues: any) => {
        setSlots(allValues.slots);
        history.replace("/maker?" + compress(allValues));
    };

    //Open sharing modal
    const onOpenSharingModal = () => {
        const { objectiveId, solutions } = form.getFieldsValue();
        if (!objectiveId) {
            notification.warn({ message: "Vous devez choisir un objectif." });
        } else if (!solutions.length) {
            notification.warn({
                message: "Vous devez fournir au moins une solution.",
            });
        } else {
            setSharedUrl(window.location.href.replace("/maker?", "/solver?"));
            toggleSharing();
        }
    };

    const onCopy = () => {
        copy(sharedUrl);
        notification.success({ message: "Lien copié dans le presse-papier!" });
    };

    //Form
    const configForm = {
        form,
        onValuesChange,
        layout: "inline" as "inline",
    };

    //Sharing
    const configModalSharing = {
        open: openSharing,
        onCancel: toggleSharing,
        title: "Partager mon Puzzle",
        footer: null,
    };

    //Autosize the maker
    useEffect(resize, [size, viewportHeight]);

    //Read URL on first load
    useMount(() => {
        const values = decompress();
        if (values.slots.length > 0) {
            form.setFieldsValue(values);
            setSlots(values.slots);
        }
    });

    return (
        <StyledDiv ref={ref} style={{ maxWidth }}>
            <Form {...configForm}>
                <Form.Item
                    name="slots"
                    valuePropName="slots"
                    initialValue={defaultSlots}
                    style={{ margin: 0, width: "100%" }}
                >
                    <Game />
                </Form.Item>
                <Form.Item
                    label="Objectif"
                    name="objectiveId"
                    initialValue={null}
                    required
                >
                    <Select
                        options={objectivesOptions}
                        placeholder="Choisir l'objectif du puzzle..."
                        dropdownMatchSelectWidth={false}
                        optionLabelProp="name"
                        style={{ width: "auto" }}
                    />
                </Form.Item>
                <Form.Item
                    label="HP ennemi"
                    name="enemyHp"
                    initialValue={null}
                    tooltip={{
                        title: "Laissez ce champ vide pour ne pas afficher la ligne du joueur adverse",
                    }}
                >
                    <InputNumber min={2} max={20} style={{ width: 50 }} />
                </Form.Item>
                <Form.Item
                    label="Solutions Strictes"
                    name="strictSolutions"
                    initialValue={false}
                    tooltip={{
                        title: "Si vous activez les solutions strictes, alors l'ordre des cartes sera important, pas juste leur position.",
                    }}
                    valuePropName="checked"
                >
                    <Switch />
                </Form.Item>
                <Form.Item label="Difficulté" name="rating" initialValue={1}>
                    <Rate allowHalf />
                </Form.Item>
                <Form.Item
                    label="Solutions"
                    name="solutions"
                    initialValue={[]}
                    required
                >
                    <InputPickerSolution slots={slots} />
                </Form.Item>
                <Form.Item>
                    <Button
                        className="btn-share"
                        type="primary"
                        icon={<ShareAltOutlined />}
                        onClick={onOpenSharingModal}
                    >
                        Partager
                    </Button>
                </Form.Item>
            </Form>

            <Modal {...configModalSharing}>
                <Alert
                    type="error"
                    icon={<WarningFilled />}
                    message="Attention, si vous réalisez des modifications après avoir partagé votre lien de résolution, il vous faudra générer et partager un nouveau lien! Vérifiez bien vos solutions!"
                />
                <Input.Group style={{ marginTop: 20 }}>
                    <Input
                        value={sharedUrl}
                        style={{ width: "calc(100% - 32px)" }}
                    />
                    <Button
                        type="primary"
                        icon={<CopyOutlined />}
                        onClick={onCopy}
                    />
                </Input.Group>

                <div style={{ textAlign: "center", marginTop: 20 }}>
                    <small>
                        Vous pouvez utiliser{" "}
                        <a href="https://www.shorturl.at/" target="_blank">
                            shorturl.at
                        </a>{" "}
                        pour générer une URL plus courte.
                    </small>
                </div>
            </Modal>
        </StyledDiv>
    );
};

export default PageMaker;
