import React, { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { useControllableValue, useToggle } from "ahooks";
import { Button, Modal } from "antd";
import Solver from "../Solver";
import { ISlot } from "../../types/slot";
import DisplaySolution from "../display/SolutionTag";
import { ISolution } from "../../types/solution";
import { maxBy, findIndex } from "lodash";
import styled from "styled-components";

const StyledDiv = styled.div`
    .ant-tag:hover {
        cursor: pointer;
        background: #ccc;
    }
`;

const InputPickerSolution = ({ slots, ...props }: { slots: ISlot[] }) => {
    const [solutions, setSolutions] = useControllableValue<ISolution[]>(props);
    const [visible, { toggle }] = useToggle(false);
    const [currentSolution, setCurrentSolution] = useState<ISolution>({
        arrows: [],
    });

    //Open the modal to add or edit a solution
    const onOpen = (sol?: any) => {
        setCurrentSolution(sol ?? { arrows: [] });
        toggle();
    };

    //Remove a solution
    const onDeleteSolution = (toDelete: ISolution, event: any) => {
        event.preventDefault();
        Modal.confirm({
            title: "Supprimer cette solution?",
            content: <DisplaySolution solution={toDelete} slots={slots} />,
            onOk: () => {
                solutions.splice(findIndex(solutions, toDelete), 1);
                setSolutions([...solutions]);
            },
        });
        return false;
    };

    //Save the modal
    const onSave = () => {
        if (currentSolution.id) {
            //Solution exists, we are editing so replace it
            solutions.splice(
                findIndex(solutions, { id: currentSolution.id }),
                1,
                currentSolution
            );
            setSolutions([...solutions]);
        } else {
            //Solution needs to be created
            currentSolution.id = (maxBy(solutions, "id")?.id ?? 0) + 1;
            setSolutions([...solutions, currentSolution]);
        }

        //Close modal
        toggle();
    };

    //
    const configModal = {
        centered: true,
        open: visible,
        width: "80%",
        onCancel: toggle,
        maskClosable: false,
        closable: false,
        onOk: onSave,
        okText: "Sauvegarder",
        cancelText: "Annuler",
        okButtonProps: { disabled: !currentSolution.arrows.length },
        bodyStyle: { paddingBottom: 10 },
    };

    //
    return (
        <StyledDiv>
            {solutions.map((s) => (
                <DisplaySolution
                    key={s.id}
                    solution={s}
                    slots={slots}
                    closable
                    onClose={(e) => onDeleteSolution(s, e)}
                    onClick={() => onOpen(s)}
                />
            ))}
            <Button
                icon={<PlusOutlined />}
                onClick={() => onOpen()}
                type="primary"
            />
            <Modal {...configModal}>
                <Solver
                    slots={slots}
                    value={currentSolution}
                    onChange={setCurrentSolution}
                />
            </Modal>
        </StyledDiv>
    );
};

export default InputPickerSolution;
