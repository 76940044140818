import axiosClient from "axios";

const axios = axiosClient.create({
    baseURL: import.meta.env.REACT_APP_SERVER_ROOT,
    //mode: 'no-cors',
    withCredentials: true,
    headers: {
        // accept: 'application/ld+json',
    },
});

/**
 * Intercept axios errors to notify everything
 */
axios.interceptors.response.use(({ data }) => data);

export default axios;
