import styled from "styled-components";
import { sizes } from "../../config/sizes";
import { useRef } from "react";
import { useSize } from "ahooks";

const scale = 1.4;

const StyledDiv = styled.div<{
    bridgeWidth?: number;
    bridgeHeight?: number;
    offset: number;
}>`
    .bridge-card {
        z-index: 10;
        position: absolute;
        width: ${(p) => p.bridgeWidth}px;
        height: ${(p) => p.bridgeHeight}px;
        border-radius: 6px;
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.75);
        display: inline-block;

        background-image: url("/images/bridges/pont-initiation.png");
        background-size: contain;

        left: calc((${(p) => p.bridgeWidth}px - 100%) / -2);
    }

    &:first-child .bridge-card {
        top: ${(p) => p.offset}px;
    }
    &:last-child .bridge-card {
        bottom: ${(p) => p.offset}px;
    }
`;

/**
 *
 * @param id
 * @constructor
 */
const DisplayBridge = ({ height }: { height: number }) => {
    const ref = useRef(null);
    const size = useSize(ref);

    const bridgeWidth = (size?.width ?? 0) * 1.1;
    const bridgeHeight = bridgeWidth / sizes.card.ratio;
    const offset = (height - bridgeHeight) / 2 + height / 4;

    return (
        <StyledDiv
            ref={ref}
            className={"bridge"}
            bridgeWidth={bridgeWidth}
            bridgeHeight={bridgeHeight}
            offset={offset}
        >
            <div className="bridge-card" />
        </StyledDiv>
    );
};

export default DisplayBridge;
