import React, { useRef, useState } from "react";
import { useMount, useToggle } from "ahooks";
import useJsonCrush from "../hooks/json-crush";
import styled from "styled-components";
import Solver from "../component/Solver";
import { IObjective } from "../types/objective";
import { useInterface } from "../contexts/interface";
import EnemyHp from "../component/display/EnemyHp";
import { ISolution } from "../types/solution";
import { Button, Modal, notification, Rate } from "antd";
import { CheckCircleFilled, CopyOutlined } from "@ant-design/icons";
import { isEqual, intersection } from "lodash";
import DisplaySolutionTag from "../component/display/SolutionTag";
import copy from "copy-to-clipboard";
import { IArrow } from "../types/arrow";
import { ISlot } from "../types/slot";
import { CaretRightFilled } from "@ant-design/icons/lib";
import { find } from "lodash";

const StyledDiv = styled.div`
    position: relative;

    .btn-check {
        display: inline-block;
        .ant-btn {
            margin-left: 20px;
        }
    }

    .header {
        position: relative;
        margin: auto;
        max-width: 1440px;
        min-height: 30px;
        .enemy-hp {
            display: inline-block;
        }
        h1 {
            font-size: 130%;
            position: absolute;
            right: 1vw;
            top: 0;
            text-transform: uppercase;

            .ant-rate {
                position: relative;
                top: -3px;
                margin-left: 10px;
                font-size: 100%;
            }
        }
    }

    .footer {
        margin-top: 15px;
        text-align: center;
    }

    .celebration {
        width: 100%;
        min-height: calc((70vw - 48px) / 1.79);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        &.win {
            background-image: url("/images/celebrations/win.jpg");
            height: 300px;
        }
        &.lose {
            background-image: url("/images/celebrations/lose.jpg");
        }
    }

    .counter {
        text-align: center;
        font-size: 20px;
        height: 130px;
        .ant-btn {
            margin-top: 15px;
        }
    }

    .celebration-modal {
        .ant-modal-body {
            padding: 0;
        }
        .ant-modal-footer {
            text-align: center;
            .ant-btn {
                width: 50%;
            }
        }
        .ant-tag:last-of-type {
            margin-right: 0;
        }
    }
`;

//Stringify arrows for comparison
const stringifySolution = (s: ISolution) => {
    return s.arrows.map((a) => a.sourceSlotId + "_" + a.targetSlotId);
};

//Stringify arrows for human string
const humanizeSolution = (s: ISolution, slots: ISlot[]) => {
    return s.arrows
        .map((arrow: IArrow) => {
            const source = find(slots, { id: arrow.sourceSlotId })?.name;
            const target = find(slots, { id: arrow.targetSlotId })?.name;
            return source + ">" + target;
        })
        .join(" + ");
};

/**
 * This page will initialize the Maker and arrange lines & the deck
 * @constructor
 */
const PageSolver = ({ ...props }) => {
    const { objectives } = useInterface();
    const [slots, setSlots] = useState([]);
    const [enemyHp, setEnemyHp] = useState(null);
    const [solutions, setSolutions] = useState<ISolution[]>([]);
    const [strictSolutions, setStrictSolutions] = useState(false);
    const [objective, setObjective] = useState<IObjective>();
    const [counter, setCounter] = useState(1);
    const [solution, setSolution] = useState<ISolution | null>(null);
    const [rating, setRating] = useState<number>(0);
    const [open, { toggle }] = useToggle();
    const { decompress } = useJsonCrush();
    const [resolved, setResolved] = useState(false);
    const ref = useRef(null);

    const configModal = {
        open,
        getContainer: ref.current as any,
        centered: true,
        onCancel: toggle,
        onOk: toggle,
        title: resolved ? "Bravo!" : "Caramba, encore raté!",
        maskClosable: !resolved, //we want the celebration to be harder to close
        className: "celebration-modal",
        cancelButtonProps: { style: { display: "none" } },
        footer: resolved ? null : undefined,
        width: "70vw",
    };

    /**
     *
     */
    const onValidate = () => {
        if (!solution) {
            return;
        }
        let resolved = false;

        if (strictSolutions) {
            //Arrays must be strictly identical
            solutions.forEach((tested) => {
                if (isEqual(tested.arrows, solution.arrows)) resolved = true;
            });
        } else {
            //Create a stringyfied version of our arrows
            solutions.forEach((tested) => {
                if (
                    intersection(
                        stringifySolution(tested),
                        stringifySolution(solution)
                    ).length === tested.arrows.length
                ) {
                    resolved = true;
                }
            });
        }

        //Increment counter if unresolved
        if (!resolved) {
            setCounter(counter + 1);
        }

        setResolved(resolved);
        toggle();
    };

    //Copy a link for discord
    const onShareDiscord = () => {
        if (solution) {
            const solutionAsString = humanizeSolution(solution, slots);
            copy(
                `J'ai réussi à résoudre le grimoire en ${counter} coup${
                    counter > 1 ? "s" : ""
                }! Ma solution: ||${solutionAsString}||`
            );
            notification.success({
                message: "Message copié dans le presse-papier!",
            });
        }
    };

    //Read URL on first load
    useMount(() => {
        const {
            slots,
            enemyHp,
            solutions,
            strictSolutions,
            objectiveId,
            rating,
        } = decompress();
        if (slots.length) {
            setSlots(slots);
            setEnemyHp(enemyHp);
            setStrictSolutions(strictSolutions ? true : false);
            setSolutions(solutions);
            setRating(rating);
            setObjective(objectives[objectiveId]);
        }
    });

    //Extra button
    const extra = (
        <div className="btn-check">
            <Button
                type="primary"
                size="large"
                icon={<CheckCircleFilled />}
                onClick={onValidate}
                disabled={!solution}
            >
                Valider ma réponse
            </Button>
        </div>
    );

    return (
        <StyledDiv ref={ref}>
            <div className="header">
                <EnemyHp value={enemyHp} />
                <h1>
                    {objective?.name}{" "}
                    <Rate value={rating} count={rating} allowHalf disabled />
                </h1>
            </div>

            <Solver slots={slots} onChange={setSolution} extra={extra} />

            <div className="footer">
                {/*<Rate value={rating} allowHalf disabled/>&nbsp;&nbsp;&nbsp;*/}
                <b>{objective?.name}</b> - {objective?.description}
            </div>

            <Modal {...configModal}>
                <div className={"celebration " + (resolved ? "win" : "lose")} />
                {resolved && (
                    <div className="counter">
                        {counter} Essai{counter > 1 ? "s" : ""}
                        <div>
                            <DisplaySolutionTag
                                slots={slots}
                                solution={solution as ISolution}
                            />
                        </div>
                        <Button
                            icon={<CopyOutlined />}
                            onClick={onShareDiscord}
                            type="primary"
                            size="large"
                        >
                            Copier une annonce pour le discord
                        </Button>
                    </div>
                )}
            </Modal>
        </StyledDiv>
    );
};

export default PageSolver;
