export interface ICard {
    id: number;
    name: string;
    extension: number;
    type: number;
    aura: number | null;
    abilities: number[];
    atk: number;
    hp: number;
    mana: number;
    image: string;
    uid: string;
    playable?: boolean;

    inSlot?: number;
}

export enum ICardKeys {
    id = "id",
    name = "name",
    aura = "aura",
    abilities = "abilities",
    atk = "atk",
    hp = "hp",
    mana = "mana",
}

export enum ICardTypes {
    champion,
    creature,
    incantation,
}

export interface ICardsCollection extends Record<string, ICard> {}
