import styled from "styled-components";
import { ICard } from "../types/card";
import { useControllableValue } from "ahooks";
import Slot from "./Slot";
import { ISlot } from "../types/slot";
import { find, cloneDeep } from "lodash";

const StyledDiv = styled.div`
    width: 100%;
    .slot-wrapper {
        display: inline-block;
        min-height: 50px;
    }
`;

/**
 * A Line manages Slots to drop cards into, and also updates the slots configuration
 * @param id
 * @param style
 * @param props
 * @constructor
 */
const Line = ({
    id,
    locked = false,
    style,
    ...props
}: {
    id: number;
    slots: ISlot[];
    locked?: boolean;
    onChange?: any;
    style?: any;
}) => {
    const [slots, setSlots] = useControllableValue<ISlot[]>(props, {
        valuePropName: "slots",
    });
    const count = slots.filter((s) => id === s.lineId).length;

    //
    const onChange = (card: ICard, targetSlot: ISlot) => {
        const newSlots = cloneDeep(slots);

        // Card has been removed
        if (!card) {
            const newSlot = find(newSlots, { id: targetSlot.id });
            delete newSlot?.card;
            setSlots(newSlots, { sourceSlot: targetSlot, targetSlot, card });
            return;
        }

        const sourceSlot = card.inSlot
            ? find(newSlots, { id: card.inSlot })
            : null;

        if (locked) {
            //Do not make any change just send changes
            setSlots(newSlots, { targetSlot, sourceSlot, card });
            return;
        }

        if (sourceSlot) {
            //There was a card in the previous slot
            if (targetSlot.card) {
                //We have a card, move our card there
                sourceSlot.card = { ...targetSlot.card, inSlot: card.inSlot };
            } else {
                //We have no card, clear the slot
                delete sourceSlot.card;
            }
        }

        const droppedCard = { ...card, inSlot: targetSlot.id };
        const newSlot = find(newSlots, { id: targetSlot.id });
        if (newSlot) {
            newSlot.card = droppedCard;
        }
        setSlots(newSlots, { targetSlot, sourceSlot, card });
        console.log(card);
    };

    return (
        <StyledDiv className="line" style={style}>
            {slots.map(
                (s) =>
                    id === s.lineId && (
                        <div
                            key={s.id}
                            className="slot-wrapper"
                            style={{ width: 100 / count + "%" }}
                        >
                            <Slot
                                id={s.id}
                                slotRef={s.ref}
                                name={s.name}
                                card={s.card}
                                onChange={(card: ICard) => onChange(card, s)}
                                locked={locked}
                            />
                        </div>
                    )
            )}
        </StyledDiv>
    );
};

export default Line;
