import { Select } from "antd";
import styled from "styled-components";
import { useInterface } from "../../contexts/interface";
import React, { useMemo } from "react";
import { map } from "lodash";

const StyledSelect = styled(Select)`
    width: 100%;
    .ant-tag-close-icon {
        position: relative;
        top: -8px;
    }
`;

const InputSearchExtension = ({ ...props }) => {
    const { extensions } = useInterface();

    //Options list memoized
    const options = useMemo(
        () =>
            map(extensions, (ext) => ({
                value: ext.id,
                label: (
                    <span>
                        <b>{ext.order}</b> - {ext.name}
                    </span>
                ),
                name: ext.name,
            })),
        [extensions]
    );

    return (
        <StyledSelect
            mode="multiple"
            options={options}
            size="large"
            allowClear
            placeholder="Filtrer par Extension"
            optionFilterProp="name"
            {...props}
        />
    );
};

export default InputSearchExtension;
