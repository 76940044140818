import React from "react";
import { Spin } from "antd";
import "./App.css";
import { useInterface } from "./contexts/interface";
import PageMaker from "./pages/Maker";
import {
    BrowserRouter as Router,
    Switch as Routes,
    Route,
    Redirect,
} from "react-router-dom";
import PageSolver from "./pages/Solver";

function App() {
    const { loading } = useInterface();

    if (loading) return <Spin />;

    return (
        <Router>
            <Routes>
                <Route path="/maker">
                    <PageMaker />
                </Route>
                <Route path="/solver">
                    <PageSolver />
                </Route>
                <Redirect to="/maker" />
            </Routes>
        </Router>
    );
}

export default App;
