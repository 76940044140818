import React, { useRef } from "react";
import { ISlot } from "../types/slot";
import { Col, Row } from "antd";
import Line from "./Line";
import styled from "styled-components";
import Deck from "./Deck";
import DisplayBridge from "./display/Bridge";
import { useControllableValue, useSize } from "ahooks";

const StyledContainer = styled.div`
    margin: auto;
    max-width: 1440px;
`;

const GameRow = styled(Row)`
    position: relative;
    background-image: url("/images/tapis/water.png");
    background-size: 10%;
`;

const PlayerRow = styled(Row)`
    z-index: 2;
    box-shadow: 0px -7.5px 15px 0px rgba(0, 0, 0, 0.75);
    background-image: url("/images/tapis/wood.png");
    background-size: 20%;
`;

const ColLane = styled(Col)`
    z-index: 2;
    background-image: url("/images/tapis/base.jpg");
    background-size: cover;
    padding: 0 10px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.75);
    &.left {
        text-align: right;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }
    &.right {
        text-align: left;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }
`;

const ColPlayer = styled(Col)`
    height: 100%;
    .line {
        display: inline-block;
    }
`;

const ColBridge = styled(Col)`
    position: static;
`;

/**
 * This page will initialize the Maker and arrange lines & the deck
 * @constructor
 */
const Game = ({
    locked = false,
    noDeck = false,
    ...props
}: {
    slots?: ISlot[];
    onChange?: any;
    locked?: boolean;
    noDeck?: boolean;
}) => {
    //The bridge col does not have any height, we measure the game height to give to the bridges
    const [slots, onChange] = useControllableValue(props, {
        valuePropName: "slots",
    });
    const ref = useRef(null);
    const size = useSize(ref);

    //Player row
    const configPlayerCol = noDeck ? { span: 24 } : { span: 21 };

    return (
        <StyledContainer className="game">
            <GameRow align="middle" ref={ref}>
                <ColLane span={10} className="left">
                    <Line
                        id={1}
                        slots={slots}
                        onChange={onChange}
                        locked={locked}
                    />
                    <Line
                        id={2}
                        slots={slots}
                        onChange={onChange}
                        locked={locked}
                    />
                </ColLane>
                <ColBridge span={4}>
                    <DisplayBridge height={size?.height ?? 0} />
                    <DisplayBridge height={size?.height ?? 0} />
                </ColBridge>
                <ColLane span={10} className="right">
                    <Line
                        id={3}
                        slots={slots}
                        onChange={onChange}
                        locked={locked}
                    />
                    <Line
                        id={4}
                        slots={slots}
                        onChange={onChange}
                        locked={locked}
                    />
                </ColLane>
            </GameRow>
            <PlayerRow align="middle">
                {!noDeck && (
                    <Col span={3}>
                        <Deck />
                    </Col>
                )}
                <ColPlayer {...configPlayerCol} style={{ textAlign: "center" }}>
                    <Line
                        id={5}
                        slots={slots}
                        onChange={onChange}
                        locked={locked}
                    />
                </ColPlayer>
            </PlayerRow>
        </StyledContainer>
    );
};

export default Game;
