import * as React from "react";
import { getArrow } from "perfect-arrows";
import { ISlot } from "../types/slot";
import { useEffect, useState } from "react";
import { find, isEqual } from "lodash";
import { useDebounceFn, useSize } from "ahooks";
import { IArrow } from "../types/arrow";

const colors = [
    "#E918ED",
    "#EDC024",
    "#6D0CED",
    "#6FED24",
    "#F06518",
    "#120CF0",
    "#0CF048",
];
const getArrowColor = (id: number) => colors[(id - 1) % colors.length];

function Arrow({
    containerRef,
    slots,
    id,
    targetSlotId,
    sourceSlotId,
}: {
    containerRef: any;
    slots: ISlot[];
    id: number;
    targetSlotId: number;
    sourceSlotId: number;
}) {
    // const colors = ["#ffbe0b","#fb5607","#ff006e","#8338ec",];

    const source = find(slots, { id: sourceSlotId });
    const target = find(slots, { id: targetSlotId });
    const containerSize = useSize(containerRef);

    const [coords, setCoords] = useState<any>(null);

    //Compute the arrow coordinates, allowing to draw it
    const { run: draw } = useDebounceFn(
        () => {
            const { left: containerX, top: containerY } =
                containerRef.current.getBoundingClientRect();
            const {
                left: tX,
                top: tY,
                width: tW,
                height: tH,
            } = target?.ref.current.getBoundingClientRect() ?? {};
            const {
                left: sX,
                top: sY,
                width: sW,
                height: sH,
            } = source?.ref.current.getBoundingClientRect() ?? {};

            const newCoords = {
                source: {
                    x: sX + sW / 2 - containerX,
                    y: sY + sH * 0.1 - containerY,
                },
                target: {
                    x: tX + tW / 2 - containerX,
                    y: tY + tH / 2 - containerY,
                },
            };
            if (!isEqual(coords, newCoords)) {
                setCoords(newCoords);
            }
        },
        { wait: 50 }
    );

    //Update coordinates on arrow change
    useEffect(draw, [coords, targetSlotId, sourceSlotId, containerSize]);

    //Exit if no coordinates
    if (!coords) {
        return <span></span>;
    }

    //Retrieve elements
    const p1 = coords.source;
    const p2 = coords.target;
    const color = getArrowColor(id);

    //Generate coordinates
    const arrow = getArrow(p1.x, p1.y, p2.x, p2.y, {
        bow: 0.2,
        padEnd: 40,
    });

    const [sx, sy, cx, cy, ex, ey, ae, as, ec] = arrow;
    const endAngleAsDegrees = ae * (180 / Math.PI);

    return (
        <svg className="arrow" stroke={color} fill={color} strokeWidth={8}>
            <g>
                <path
                    d={`M${sx},${sy} Q${cx},${cy} ${ex},${ey}`}
                    fill="none"
                    stroke="#fff"
                    stroke-width={14}
                />
                <circle cx={sx} cy={sy} r={13} fill="none" stroke="#fff" />
                <polygon
                    points="0,-6 12,0, 0,6"
                    transform={`translate(${ex},${ey}) rotate(${endAngleAsDegrees})`}
                    stroke-width={14}
                    stroke="#fff"
                />
            </g>
            <g>
                <path d={`M${sx},${sy} Q${cx},${cy} ${ex},${ey}`} fill="none" />
                <circle cx={sx} cy={sy} r={10} />
                <text
                    x={sx}
                    y={sy + 5}
                    text-anchor="middle"
                    stroke="#fff"
                    stroke-width="1px"
                >
                    {id}
                </text>
                <polygon
                    points="0,-6 12,0, 0,6"
                    transform={`translate(${ex},${ey}) rotate(${endAngleAsDegrees})`}
                />
            </g>
        </svg>
    );
}

export default Arrow;
export { getArrowColor };
