import JSONCrush from "jsoncrush";
import { ISlot } from "../types/slot";
import { useInterface } from "../contexts/interface";
import { useLocation } from "react-router-dom";
import React from "react";
import { ISolution } from "../types/solution";
import { isInteger } from "lodash";

const useJsonCrush = () => {
    const { cards, objectives } = useInterface();
    const location = useLocation();

    return {
        compress: ({
            slots,
            objectiveId,
            solutions,
            enemyHp,
            strictSolutions,
            rating,
        }: {
            slots: ISlot[];
            objectiveId: number;
            solutions: ISolution[];
            enemyHp?: number;
            strictSolutions?: boolean;
            rating?: number;
        }) => {
            const chain = {
                s: slots.map((slot) => ({
                    i: slot.id,
                    l: slot.lineId,
                    n: slot.name,
                    c: slot.card?.id ?? null,
                })),
                o: objectiveId,
                r: solutions?.map((solution) =>
                    solution.arrows.map((arrow) => ({
                        s: arrow.sourceSlotId,
                        t: arrow.targetSlotId,
                    }))
                ),
                h: isInteger(enemyHp) ? enemyHp : null,
                c: strictSolutions === true ? 1 : 0,
                g: rating ?? 0,
            };

            return encodeURIComponent(JSONCrush.crush(JSON.stringify(chain)));
        },
        decompress: () => {
            const sub = decodeURIComponent(location.search.substring(1));
            if (sub) {
                const puzzle = JSON.parse(JSONCrush.uncrush(sub));

                //Retrieve slots
                const slots = puzzle.s.map(({ i, l, n, c }: any) => ({
                    id: i,
                    name: n,
                    lineId: l,
                    card: c ? { ...cards[c], inSlot: i } : null,
                    ref: React.createRef(),
                }));

                //Retrieve objective id
                const objectiveId = puzzle.o;

                //Retrieve solutions
                const solutions = puzzle.r.map((solution: any, k: number) => ({
                    id: k + 1,
                    arrows: solution.map((arrow: any, k: number) => ({
                        id: k + 1,
                        sourceSlotId: arrow.s,
                        targetSlotId: arrow.t,
                    })),
                }));

                //Retrieve enemy HP
                const enemyHp = puzzle.h;

                //Retrieve strict solutions
                const strictSolutions = puzzle.c === 1 ? true : false;

                const rating = puzzle.g ?? 0;

                return {
                    slots,
                    objectiveId,
                    solutions,
                    enemyHp,
                    strictSolutions,
                    rating,
                };
            }
            return { slots: [] };
        },
    };
};

export default useJsonCrush;
