import styled from "styled-components";
import { ISlot } from "../../types/slot";
import React, { useMemo } from "react";
import { find } from "lodash";
import { Tag } from "antd";
import { CaretRightFilled } from "@ant-design/icons/lib";
import { TagProps } from "antd/lib/tag";
import { ISolution } from "../../types/solution";
import { IArrow } from "../../types/arrow";

interface Props extends TagProps {
    solution: ISolution;
    slots: ISlot[];
}

const StyledDiv = styled.div`
    display: inline-block;
    .anticon {
        font-size: 10px;
    }
    .separator {
        display: inline-block;
        margin: 0 6px;
    }
`;

//Converts an arrow to a component
const arrowToStringComponent = (arrow: IArrow, slots: ISlot[]) => {
    const source = find(slots, { id: arrow.sourceSlotId })?.name;
    const target = find(slots, { id: arrow.targetSlotId })?.name;
    return (
        <span key={arrow.id}>
            {source}
            <CaretRightFilled />
            {target}
        </span>
    );
};

/**
 * @constructor
 */
const DisplaySolutionTag = ({ solution, slots, ...props }: Props) => {
    const items = useMemo(
        () =>
            solution.arrows.map((arrow) =>
                arrowToStringComponent(arrow, slots)
            ),
        [solution, slots]
    );

    return (
        <StyledDiv className="solution">
            <Tag {...props}>
                {items.reduce(
                    (p, c, k) =>
                        [
                            p,
                            <span key={"s" + k} className="separator"></span>,
                            c,
                        ] as any
                )}
            </Tag>
        </StyledDiv>
    );
};

export default DisplaySolutionTag;
export { arrowToStringComponent };
