import styled from "styled-components";
import { range, find } from "lodash";
import React from "react";
import Card from "../Card";
import { useInterface } from "../../contexts/interface";
import { ICard } from "../../types/card";
import { sizes } from "../../config/sizes";

const StyledDiv = styled.div`
    .card {
        height: 5vh;
        width: calc(5vh / ${sizes.card.ratio});
        margin: 0 0.2vh;
    }
    .card-line {
        margin-top: 5px;
    }
`;

const cardBack = {
    id: -1,
    image: "card-back.png",
} as ICard;

const EnemyHp = ({ value }: { value: number | undefined | null }) => {
    const { cards } = useInterface();

    if (!value || value <= 1) return <span />;

    const count = range(0, value - 1);
    const bastion = find(cards, { name: "bastion" }) as ICard;

    return (
        <StyledDiv className="enemy-hp">
            <div className="card-line">
                <Card card={bastion} />
                {count.map((v: number) => (
                    <Card key={v} card={cardBack} />
                ))}
            </div>
        </StyledDiv>
    );
};

export default EnemyHp;
