import React, { useCallback, useMemo, useRef } from "react";
import { useControllableValue } from "ahooks";
import Game from "./Game";
import { ISlot } from "../types/slot";
import { ICard, ICardTypes } from "../types/card";
import styled from "styled-components";
import Arrow, { getArrowColor } from "./Arrow";
import { IArrow } from "../types/arrow";
import { maxBy, findIndex, find, cloneDeep, sum } from "lodash";
import { notification, Tag, Tooltip } from "antd";
import { ISolution } from "../types/solution";
import { arrowToStringComponent } from "./display/SolutionTag";
import { useInterface } from "../contexts/interface";

const StyledDiv = styled.div`
    position: relative;

    .arrows {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1000;
        width: 100%;
        height: 100%;
        pointer-events: none;
        svg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    .solver-solution {
        text-align: center;
        height: 22px;
        margin-top: 10px;
    }

    .solver-info {
        font-style: italic;
        color: #999;
    }

    .mana-cost {
        display: inline-block;
        //float: right;
        background: rgba(255, 255, 255, 0.1);
        padding: 4px 8px;
        border-radius: 30px;
        height: 24px;
        line-height: 16px;

        .mana {
            display: inline-block;
            float: right;
            width: 18px;
            height: 18px;
            background-size: contain;
            background-image: url("/images/backgrounds/mana.png");
        }
    }
`;

/**
 * This page will initialize the Maker and arrange lines & the deck
 * @constructor
 */
const Solver = ({
    slots,
    extra,
    ...props
}: {
    slots: ISlot[];
    value?: ISolution;
    onChange?: any;
    extra?: any;
}) => {
    const { cards } = useInterface();
    const [solution, setSolution] = useControllableValue<ISolution>(props, {
        defaultValue: { arrows: [] },
    });
    const containerRef = useRef(null);
    const manaCost = useMemo(
        () =>
            sum(
                solution.arrows.map(
                    (a) => find(slots, { id: a.sourceSlotId })?.card?.mana ?? 0
                )
            ),
        [solution.arrows, slots]
    );

    //Add a new arrow when detecting a drop
    const onChange = useCallback(
        (
            slots: ISlot[],
            {
                targetSlot,
                sourceSlot,
                card,
            }: { targetSlot: ISlot; sourceSlot: ISlot; card: ICard }
        ) => {
            const arrows = [...solution.arrows];

            if (sourceSlot.id === targetSlot.id) {
                //Dropping on same slot, just ignore
                return;
            }

            if (
                //([3, 4].indexOf(sourceSlot.lineId) >= 0 ) || //trying to move a card from the enemy lines (disabling this line since you can kill a mercenary and replay it on your side)
                ([3, 4].indexOf(targetSlot.lineId) >= 0 && sourceSlot.card?.type !== ICardTypes.incantation) || //trying to place something in enemy side
                ([1, 2].indexOf(sourceSlot.lineId) < 0 && sourceSlot.lineId === targetSlot.lineId) || //trying to move a card in the same line. Allowed for our side only (kill & replay card)
                sourceSlot.card?.playable === false //trying to move an unplaybale card
            ) {
                notification.warning({ message: "Mouvement impossible" });
                return;
            }

            arrows.push({
                id: (maxBy(arrows, "id")?.id ?? 0) + 1,
                sourceSlotId: sourceSlot.id,
                targetSlotId: targetSlot.id,
            });

            setSolution({ ...solution, arrows });
        },
        [solution]
    );

    //Remove an arrow
    const onRemoveArrow = (arrow: IArrow, event: any) => {
        event.preventDefault();
        const arrows = cloneDeep(solution.arrows);
        arrows.splice(findIndex(arrows, { id: arrow.id }), 1);
        setSolution({
            ...solution,
            arrows: arrows.map((arrow, k) => ({ ...arrow, id: k + 1 })),
        }); //renumber arrows before updating
    };

    return (
        <StyledDiv ref={containerRef}>
            <Game slots={slots} onChange={onChange} noDeck locked />
            <div className="arrows">
                {solution.arrows.map((arrow) => (
                    <Arrow
                        containerRef={containerRef}
                        key={arrow.id}
                        id={arrow.id}
                        slots={slots}
                        sourceSlotId={arrow.sourceSlotId}
                        targetSlotId={arrow.targetSlotId}
                    />
                ))}
            </div>
            <div className="solver-solution">
                {solution.arrows.length > 0 && (
                    <div>
                        {solution.arrows.map((arrow) => (
                            <Tag
                                key={arrow.id}
                                closable
                                onClose={(e) => onRemoveArrow(arrow, e)}
                                color={getArrowColor(arrow.id)}
                            >
                                {arrowToStringComponent(arrow, slots)}
                            </Tag>
                        ))}
                        <Tooltip title="Coût en mana">
                            <div className="mana-cost">
                                {manaCost}&nbsp;
                                <div className="mana" />
                            </div>
                        </Tooltip>
                        {extra}
                    </div>
                )}
                {!solution.arrows.length && (
                    <div className="solver-info">
                        Glissez les cartes sur leurs emplacements pour créer
                        votre solution!
                    </div>
                )}
            </div>
        </StyledDiv>
    );
};

export default Solver;
