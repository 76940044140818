import React, { useMemo } from "react";
import { Select, Tag } from "antd";
import { useInterface } from "../../contexts/interface";
import { map, sortBy } from "lodash";
import DisplayAbility from "../display/Ability";
import styled from "styled-components";
import { CustomTagProps } from "rc-select/lib/BaseSelect";

const StyledSelect = styled(Select)`
    width: 100%;
    .ant-tag-close-icon {
        position: relative;
        top: -8px;
    }
`;

const StyledSelectRow = styled.div`
    height: 24px;
    line-height: 24px;
    .ability {
        float: left;
        margin: 0 5px 0 0;
    }
`;

const InputSelectAbility = ({ ...props }) => {
    const { abilities } = useInterface();

    //Options list memoized
    const options = useMemo(
        () =>
            sortBy(map(abilities, (ability) => ({
                value: ability.id,
                label: (
                    <StyledSelectRow>
                        <DisplayAbility id={ability.id} size={24} />{" "}
                        {ability.name}
                    </StyledSelectRow>
                ),
                name: ability.name,
            })), "name"),
        [abilities]
    );

    //Render for tags is only the icon
    const tagRender = ({ value, closable, onClose }: CustomTagProps) => {
        return (
            <Tag closable={closable} onClose={onClose}>
                <DisplayAbility id={value} size={24} />
            </Tag>
        );
    };

    return (
        <StyledSelect
            mode="multiple"
            options={options}
            size="large"
            allowClear
            placeholder="Filtrer par Capacités"
            {...props}
            tagRender={tagRender}
        />
    );
};

export default InputSelectAbility;
