import { createContext, useContext } from "react";
import { useRequest } from "ahooks";
import axios from "../services/axios";
import { ICard } from "../types/card";
import { keyBy } from "lodash";
import { IAbility } from "../types/ability";
import { IExtension } from "../types/extension";

const Context = createContext({
    cards: {} as Record<number, ICard>,
    abilities: {} as Record<number, IAbility>,
    extensions: {} as Record<number, IExtension>,
    objectives: {} as Record<number, any>,
    loading: true,
});

export const InterfaceContextProvider = ({ children }: any) => {
    const { data, loading } = useRequest<any, any>(() =>
        axios.get("clash.json")
    );
    const { cards, abilities, extensions, objectives } = data ?? {};

    const values = {
        cards: keyBy(cards, "id"),
        abilities: keyBy(abilities, "id"),
        extensions: keyBy(extensions, "id"),
        objectives: keyBy(objectives, "id"),
        loading,
    };

    return <Context.Provider value={values}>{children}</Context.Provider>;
};

export const useInterface = () => {
    return useContext(Context);
};
