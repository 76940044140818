import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import { InterfaceContextProvider } from "./contexts/interface";
import { DndProvider } from "react-dnd-multi-backend";
import { HTML5toTouch } from "rdndmb-html5-to-touch"; // or any other pipeline

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <InterfaceContextProvider>
            <DndProvider options={HTML5toTouch}>
                <App />
            </DndProvider>
        </InterfaceContextProvider>
    </React.StrictMode>
);
