import styled from "styled-components";
import { useDrag } from "react-dnd";
import { sizes } from "../config/sizes";
import { ICard } from "../types/card";

const StyledDiv = styled.div`
    position: relative;
    width: ${sizes.card.width}px;
    height: ${sizes.card.height}px;
    border-radius: 6px;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.75);
    display: inline-block;
    background: rgba(0, 0, 0, 0.25);

    &.creature {
        .card-type {
            background-image: url("/images/types/creature.png");
        }
    }
    &.champion {
        .card-type {
            background-image: url("/images/types/champion.png");
        }
    }
    &.incantation {
        .card-type {
            background-image: url("/images/types/incantation.png");
        }
        .def {
            display: none;
        }
    }

    .card-image {
        z-index: 1;
        position: absolute;
        height: 100%;
        width: 100%;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .mana,
    .atk,
    .hp {
        position: absolute;
        z-index: 10;
        color: #fff;
        height: 20px;
        width: 20px;
        line-height: 20px;
        text-align: center;
        user-select: none;
    }

    .mana {
        top: 13px;
        left: 13px;
    }
    .atk {
        left: 12px;
        bottom: 12px;
    }
    .hp {
        right: 12px;
        bottom: 12px;
    }
`;

/**
 * Displays a draggable Card
 * @param id
 * @constructor
 */
const Card = ({
    card,
    onDragStart,
    onDragEnd,
}: {
    card: ICard;
    onDragStart?: any;
    onDragEnd?: any;
}) => {
    const [{ opacity }, dragRef] = useDrag(
        () => ({
            type: "CARD",
            item: () => {
                if (onDragStart) onDragStart(); //if something should happen when we start dragging
                return card;
            },
            end: onDragEnd,
            collect: (monitor) => ({
                opacity: monitor.isDragging() ? 0.5 : 1,
            }),
        }),
        [card]
    );

    return (
        <StyledDiv
            className={"card " + card.type}
            ref={dragRef}
            style={{ opacity }}
        >
            <div
                className={"card-image"}
                style={{
                    backgroundImage:
                        'url("/images/cards/' +
                        encodeURIComponent(card.image) +
                        '")',
                }}
            />

            {/*<div className="mana">{card.mana}</div>*/}
            {/*<div className="atk">{card.atk}</div>*/}
            {/*<div className="hp">{card.hp}</div>*/}
        </StyledDiv>
    );
};

export default Card;
